:root {
  --off-white: #fafafa;
  text-align: justify;
  text-justify: inter-word;
  margin: 2rem;
}

.post {
  line-height: 2rem;
}

a:link {
  text-decoration: none;
}